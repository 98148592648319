import {
  changeWasmArch,
  changeWasmStep,
  changeWasmView,
  toggleEditingMode,
  toggleIpr,
  toggleMaintainContacts,
  toggleAutoAdjust,
  toggleWasmOption,
} from '../../../components/wasm_viewer/wasm_controller/wasm_controller';
import { deepDiffMapper } from '../../../components/wasm_viewer/wasm_helper';
import {
  CHANGE_ARCH,
  CHANGE_STEP,
  CHANGE_VIEW,
  RESET_STATE,
  SAVE_DRAFT_END,
  SAVE_DRAFT_ERROR,
  SAVE_DRAFT_START,
  SAVE_DRAFT_SUCESS,
  SET_ACTIVE_TEETH,
  SET_ACTIVE_TREATMENT_PLAN,
  SET_IS_RESET_ENABLED,
  SET_LOADING,
  SET_MISSING_TEETH,
  SET_REVISE,
  SET_REVISE_ON_CASE_DETAILS_SUCCESS,
  SET_REVISION_NOTE,
  SET_TREATMENT_PLAN_LIST,
  TOGGLE_OPTION,
  UPDATE_EDITING_MODE,
  UPDATE_HISTORY,
  UPDATE_INITIAL_IPR,
  UPDATE_INITIAL_MOVEMENT_TABLE,
  UPDATE_IPR_TABLE,
  UPDATE_IPR_DIFF,
  UPDATE_IPR_STATE,
  UPDATE_MOVEMENT_TABLE,
  UPDATE_MOVEMENT_TABLE_DIFF,
  SET_TMT_INCREMENT_DELTA,
  SET_SELECTED_PLAN_ERROR,
  SET_LAST_ACTIVE_TREATMENT_PLAN,
  SET_REVISED_TREATMENT_PLAN,
  SAVE_REVISION_START,
  SAVE_REVISION_SUCCESS,
  SAVE_REVISION_ERROR,
  SAVE_REVISION_END,
  SET_SIDEBAR_ACTIVE_BUTTON,
  SET_SIDEBAR_COLLAPSE,
  SET_SIDEBAR_WIDTH,
  EXPAND_SIDEBAR,
  COLLAPSE_SIDEBAR,
  SET_MANDIBLE_SHIFT_VALUES,
  SET_VISIBILITY_PROPS,
  UPDATE_MOVEMENT,
  START_EDITING_REVISION_ERROR,
  START_EDITING_REVISION_END,
} from '../../actions/wasm_viewer/wasm_viewer';
import { VIEW, HORIZONTAL_VIEWS, ARCH, IdToEditingMode, WASM_IPR_MODE } from '../../../components/wasm_viewer/wasm-constants';
import { CaseStatus, ECaseProcessStatus } from '../../../common/case/case_details.constants';
import { EDeltaType, ETmtKey } from '../../../components/wasm_viewer/movement_table/constants';
import { sidebarDefaultWidth, sidebarMinWidth, defaultSidebarActiveButton } from '../../../components/wasm_viewer/sidebar/sidebar_constants';
import { WASM_PROP } from '../../../components/wasm_viewer/wasm_controller/wasm_controller_const';

const initialState = {
  loading: true,
  error: false,
  selectedTreatmentPlanError: undefined,
  isLoadCaseRetry: false,

  undo: false,
  redo: false,
  step: 1,
  gingiva: true,
  superimpose: false,
  grid: false,
  tmt: false,
  mandibleShift: {
    ignored: false,
  },
  animation: false,
  view: 'Front',
  arch: 'both',
  iprState: {
    ipr: false,
    maintainContacts: false,
    autoAdjust: false,
  },
  active_teeth: '',
  save_draft_loading: false,
  save_draft_success: false,
  save_draft_error: false,
  is_revising: false,
  revisionSuccess: false,

  initial_movement_table: undefined,
  movement_table: undefined,
  movement: ETmtKey.Occlusal,
  tmtDiff: undefined,
  initial_ipr_table: {},
  ipr_table: {},
  iprDiff: undefined,

  treatment_plan_list: undefined,
  active_treatment_plan: -1,
  lastActiveTreatmentPlan: undefined,
  revisedTreatmentPlan: undefined,
  missing_teeth: [],
  editingModeState: undefined,
  isResetEnabled: false,
  revisionNote: '',

  tmtIncrDelta: {
    [EDeltaType.Angular]: 1,
    [EDeltaType.Spacial]: 0.1,
  },

  sidebar: {
    activeButton: defaultSidebarActiveButton,
    collapse: false,
    width: sidebarDefaultWidth,
  },

  visibilityProcessor: {
    areBracketsVisible: false,
    bracketsTransparency: 0,
    areGingivaVisible: false,
    gingivaTransparency: 0,
    isLowerJawVisible: false,
    areLowerTeethVisible: false,
    lowerTeethTransparency: 0,
    isUpperJawVisible: false,
    areUpperTeethVisible: false,
    upperTeethTransparency: 0,
  },
};

export function wasmViewerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, loading: action.loading, error: action.error, isLoadCaseRetry: action.isRetry };
    case UPDATE_HISTORY:
      return updateHistory(state, action);
    case CHANGE_ARCH:
      return changeArch(state, action);
    case CHANGE_VIEW:
      return changeView(state, action);
    case CHANGE_STEP:
      return changeStep(state, action);
    case TOGGLE_OPTION:
      return toggleOption(state, action);
    case UPDATE_INITIAL_MOVEMENT_TABLE:
      return { ...state, initial_movement_table: action.movement };
    case UPDATE_MOVEMENT_TABLE:
      return updateMovementTable(state, action);
    case UPDATE_MOVEMENT_TABLE_DIFF:
      return updateMovementTableDiff(state, action);
    case UPDATE_MOVEMENT:
      return { ...state, movement: action.movement };
    case UPDATE_INITIAL_IPR:
      return updateInitialIPR(state, action);
    case UPDATE_IPR_TABLE:
      return updateIprTable(state, action);
    case UPDATE_IPR_STATE:
      return updateIprState(state, action);
    case UPDATE_IPR_DIFF:
      return updateIPRDiff(state, action);
    case RESET_STATE:
      return resetState(state);
    case SAVE_DRAFT_START:
      return saveDraftStart(state);
    case SAVE_DRAFT_SUCESS:
      return saveDraftSucess(state);
    case SAVE_DRAFT_ERROR:
      return saveDraftError(state);
    case SAVE_DRAFT_END:
      return saveDraftEnd(state);
    case START_EDITING_REVISION_ERROR:
      return startEditingRevisionError(state);
    case START_EDITING_REVISION_END:
      return startEditingRevisionEnd(state);
    case SAVE_REVISION_START:
      return saveRevisionStart(state);
    case SAVE_REVISION_SUCCESS:
      return saveRevisionSuccess(state);
    case SAVE_REVISION_ERROR:
      return saveRevisionError(state);
    case SAVE_REVISION_END:
      return saveRevisionEnd(state);
    case SET_REVISE:
      return setRevise(state, action);
    case SET_TREATMENT_PLAN_LIST:
      return setTreatmentPlanList(state, action);
    case SET_ACTIVE_TREATMENT_PLAN:
      return setActiveTreatmentPlan(state, action);
    case SET_MISSING_TEETH:
      return { ...state, missing_teeth: action.missing_teeth };
    case SET_ACTIVE_TEETH:
      return { ...state, active_teeth: action.teeth };
    case UPDATE_EDITING_MODE:
      return updateEditingMode(state, action);
    case SET_IS_RESET_ENABLED:
      return { ...state, isResetEnabled: action.isResetEnabled };
    case SET_TMT_INCREMENT_DELTA:
      return updateTMTIncrement(state, action);
    case SET_REVISION_NOTE:
      return { ...state, revisionNote: action.note || '' };
    case SET_REVISE_ON_CASE_DETAILS_SUCCESS:
      return setReviseOnCaseDetailsSuccess(state, action);
    case SET_TMT_INCREMENT_DELTA:
      return updateTMTIncrement(state, action);
    case SET_SELECTED_PLAN_ERROR:
      return updateSelectedPlanError(state, action);
    case SET_LAST_ACTIVE_TREATMENT_PLAN:
      return updateLastActiveTreatmentPlan(state, action);
    case SET_REVISED_TREATMENT_PLAN:
      return updateRevisedTreatmentPlan(state, action);
    case SET_SIDEBAR_ACTIVE_BUTTON:
      return updateSidebarActiveButton(state, action);
    case SET_SIDEBAR_COLLAPSE:
      return updateSidebarCollapse(state, action);
    case SET_SIDEBAR_WIDTH:
      return updateSidebarWidth(state, action);
    case EXPAND_SIDEBAR:
      return expandSidebar(state);
    case COLLAPSE_SIDEBAR:
      return collapseSidebar(state);
    case SET_MANDIBLE_SHIFT_VALUES:
      return { ...state, mandibleShift: { ...state.mandibleShift, ...action.payload } };
    case SET_VISIBILITY_PROPS:
      return { ...state, visibilityProcessor: { ...state.visibilityProcessor, ...action.payload } };
    default:
      return state;
  }
}

function setActiveTreatmentPlan(state, action) {
  return { ...state, active_treatment_plan: action.active_plan };
}

function setTreatmentPlanList(state, action) {
  if (state.treatment_plan_list?.length !== action.list.length) {
    return { ...state, treatment_plan_list: action.list, active_treatment_plan: action.index };
  }
  return { ...state, treatment_plan_list: action.list };
}

function setRevise(state, action) {
  performIprAction(action.isRevising, state.iprState);
  return { ...state, is_revising: action.isRevising };
}

/**
 * Sets revise when case details are fetched successfully
 * @param state {typeof initialState}
 * @param action {{
 *   type: String,
 *   case_details: {
 *     case_id: String,
 *     cases: Array<{
 *       case_id: String,
 *       status_code: CaseStatus,
 *       status_message: String,
 *       process_status_code: ECaseProcessStatus,
 *       process_status_text: String,
 *     }>,
 *   },
 * }}
 * @return {*&{revisionNote: (*|string), is_revising: boolean}}
 */
function setReviseOnCaseDetailsSuccess(state, action) {
  const { case_id: caseId, casesMap } = action.case_details;
  const currentCase = casesMap[caseId];
  const isAwaitingDoctorDecision =
    currentCase?.status_code === CaseStatus.DoctorApproveOrReviseSetup || currentCase?.status_code === CaseStatus.StatusDoctorApproval;
  const isRevisionDraftProcess = currentCase?.process_status_code === ECaseProcessStatus.RevisionDraft;
  const isRevising = isAwaitingDoctorDecision && isRevisionDraftProcess;
  const revisionNote = isRevising ? currentCase?.process_status_text : state.revisionNote;
  return { ...state, is_revising: isRevising, revisionNote };
}

function startEditingRevisionError(state) {
  return { ...state, start_editing_revision_error: true };
}

function startEditingRevisionEnd(state) {
  return { ...state, start_editing_revision_error: false };
}

function saveRevisionStart(state) {
  return { ...state, save_revision_loading: true, save_revision_success: false, save_revision_error: false };
}

function saveRevisionSuccess(state) {
  return { ...state, save_revision_success: true };
}

function saveRevisionError(state) {
  return { ...state, save_revision_error: true };
}

function saveRevisionEnd(state) {
  return { ...state, save_revision_loading: false, save_revision_success: false, save_revision_error: false };
}

function saveDraftStart(state) {
  return { ...state, save_draft_loading: true, save_draft_success: false, save_draft_error: false };
}

function saveDraftSucess(state) {
  return { ...state, save_draft_success: true };
}

function saveDraftError(state) {
  return { ...state, save_draft_error: true };
}

function saveDraftEnd(state) {
  return { ...state, save_draft_loading: false, save_draft_success: false, save_draft_error: false };
}

function resetState(state) {
  return {
    ...state,
    movement_table: state.initial_movement_table,
    tmtDiff: undefined,
    ipr_table: state.initial_ipr_table,
    iprDiff: undefined,
  };
}

function updateIprTable(state, action) {
  const ipr_table_diff = deepDiffMapper.map(state.ipr_table, action.ipr);
  return { ...state, ipr_table: action.ipr, ipr_table_diff };
}

function updateInitialIPR(state, action) {
  return { ...state, initial_ipr_table: action.ipr };
}

function updateIPRDiff(state, action) {
  return { ...state, iprDiff: action.diff };
}

function updateIprState(state, action) {
  const newIprState = getNewIPRState(state.iprState, action.iprState);
  performIprAction(state.is_revising, newIprState);
  return { ...state, iprState: newIprState };
}

function getNewIPRState(currentIprState, toggleState) {
  const { ipr, maintainContacts, autoAdjust } = currentIprState;
  switch (toggleState) {
    case WASM_IPR_MODE.initial:
      return {
        ipr: true,
        maintainContacts: false,
        autoAdjust: true,
      };
    case WASM_IPR_MODE.ipr:
      return {
        ipr: !ipr,
        maintainContacts: false,
        autoAdjust: !ipr,
      };
    case WASM_IPR_MODE.maintainContacts:
      return {
        ipr: true,
        maintainContacts: !maintainContacts,
        autoAdjust: false,
      };
    case WASM_IPR_MODE.autoAdjust:
      return {
        ipr: true,
        maintainContacts: false,
        autoAdjust: !autoAdjust,
      };

    default:
      return currentIprState;
  }
}

/**
 * @deprecated
 *
 * For combine wasm controller and redux use Actions Thunks
 */
function performIprAction(isRevising, iprState) {
  if (isRevising) {
    if (iprState.ipr && iprState.autoAdjust) {
      toggleAutoAdjust();
    } else if (iprState.ipr && iprState.maintainContacts) {
      toggleMaintainContacts();
    } else {
      toggleIpr(iprState.ipr);
    }
  } else {
    toggleIpr(iprState.ipr);
  }
}

function updateMovementTable(state, action) {
  return { ...state, movement_table: action.movement };
}

function updateMovementTableDiff(state, action) {
  return { ...state, tmtDiff: action.diff };
}

function updateHistory(state, action) {
  const { redo, undo } = action;
  return { ...state, redo: redo, undo: undo };
}

/**
 * @deprecated
 *
 * For combine wasm controller and redux use Actions Thunks
 */
function toggleOption(state, action) {
  const { option, value } = action;
  const nextOptionState = value !== null ? value : !state[option];
  toggleWasmOption(option, nextOptionState);
  return { ...state, [option]: !!nextOptionState };
}

/**
 * @deprecated
 *
 * For combine wasm controller and redux use Actions Thunks
 */
function changeArch(state, action) {
  const { arch } = action;

  const is_both_arch = state.arch === arch;
  const new_arch = is_both_arch ? ARCH.Both : arch;
  changeWasmArch(new_arch);
  return { ...state, arch: new_arch };
}

/**
 * @deprecated
 *
 * For combine wasm controller and redux use Actions Thunks
 */
function changeView(state, action) {
  const { view } = action;

  const isBackView = state.view === VIEW.Front && state.view === view;
  const nextView = isBackView ? VIEW.Back : view;
  const isCurViewHorizontal = HORIZONTAL_VIEWS.includes(state.view);
  const isNextViewHorizontal = HORIZONTAL_VIEWS.includes(view);
  const isHorizontalChange = isCurViewHorizontal && isNextViewHorizontal;
  const shouldChangeArch = !isHorizontalChange;
  const archState = {};
  if (shouldChangeArch) {
    const newArch = getViewArch(view);
    archState.arch = newArch;
    changeWasmArch(newArch);
  }
  changeWasmView(nextView);
  return { ...state, ...archState, view: nextView };
}

function getViewArch(view) {
  if (view === VIEW.Top) return ARCH.Lower;
  if (view === VIEW.Bottom) return ARCH.Upper;
  return ARCH.Both;
}

/**
 * @deprecated
 *
 * For combine wasm controller and redux use Actions Thunks
 */
function changeStep(state, action) {
  const { step } = action;
  changeWasmStep(step);
  return { ...state, step: step };
}

/**
 * @deprecated
 *
 * For combine wasm controller and redux use Actions Thunks
 */
function updateEditingMode(state, action) {
  const editingModeId = action.state;
  const nextEditingMode = IdToEditingMode[editingModeId];
  if (state.editingModeState !== editingModeId) {
    toggleEditingMode(nextEditingMode);
  }
  return { ...state, editingModeState: editingModeId };
}

/**
 * Updates redux state with tmt increment delta values
 * @param state
 * @param action {{payload: {spacial: Number, angular: Number}, type: string}}
 * @return {*&{tmtIncrDelta}}
 */
function updateTMTIncrement(state, action) {
  return { ...state, tmtIncrDelta: action.payload };
}

/**
 * Updates redux state with lastActiveTreatmentPlan index
 * @param state
 * @param action {{payload: {lastActiveTreatmentPlan: Number}, type: string}}
 * @return {*&{lastActiveTreatmentPlan: (number|*)}}
 */
function updateLastActiveTreatmentPlan(state, action) {
  return { ...state, lastActiveTreatmentPlan: action.payload.lastActiveTreatmentPlan };
}

/**
 * Updates redux state with selectedTreatmentPlanError
 * @param state
 * @param action {{payload: {error: String}, type: String}}
 * @return {*&{selectedTreatmentPlanError}}
 */
function updateSelectedPlanError(state, action) {
  return { ...state, selectedTreatmentPlanError: action.payload.error };
}

/**
 * Updates redux state with revisedTreatmentPlan
 * @param state
 * @param action {{payload: {error: String}, type: String}}
 * @return {*&{selectedTreatmentPlanError}}
 */
function updateRevisedTreatmentPlan(state, action) {
  return { ...state, revisedTreatmentPlan: action.payload.revisedTreatmentPlan };
}

/**
 * Updates the sidebar active button in the state.
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @param {string} action.payload.activeButton - The active button value.
 * @returns {Object} The updated state with the sidebar active button.
 */
function updateSidebarActiveButton(state, action) {
  return { ...state, sidebar: { ...state.sidebar, activeButton: action.payload.activeButton } };
}

/**
 * Updates the sidebar collapse state in the Redux store.
 * @param {Object} state - The current state of the Redux store.
 * @param {Object} action - The action object containing the payload.
 * @param {boolean} action.payload.collapse - The new value for the sidebar collapse state.
 * @returns {Object} - The updated state with the sidebar collapse value updated.
 */
function updateSidebarCollapse(state, action) {
  return { ...state, sidebar: { ...state.sidebar, collapse: action.payload.collapse } };
}

/**
 * Updates the width of the sidebar in the state.
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @param {number} action.payload.width - The new width of the sidebar.
 * @returns {Object} - The updated state with the new sidebar width.
 */
function updateSidebarWidth(state, action) {
  return { ...state, sidebar: { ...state.sidebar, width: action.payload.width } };
}

/**
 * Expands the sidebar in the state.
 * If the sidebar is already expanded, the state remains unchanged.
 * If the sidebar is collapsed, it expands the sidebar and adjusts the width.
 * @param {Object} state - The current state.
 * @returns {Object} The updated state with the expanded sidebar.
 */
function expandSidebar(state) {
  if (state.sidebar.collapse) {
    const width = Math.max(state.sidebar.width, sidebarDefaultWidth);
    return { ...state, sidebar: { ...state.sidebar, collapse: false, width } };
  }
  return state;
}

/**
 * Collapses the sidebar in the state object.
 * @param {Object} state - The current state object.
 * @returns {Object} - The updated state object with the collapsed sidebar.
 */
function collapseSidebar(state) {
  if (!state.sidebar.collapse) {
    const width = sidebarMinWidth;
    return { ...state, sidebar: { ...state.sidebar, collapse: true, width } };
  }
  return state;
}
