import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '../../setup_viewer/components/extras/button';
import Modal from '../../setup_viewer/components/modal/modal';
import { getWasmViewerState } from '../../../redux/selectors/wasm_viewer';
import { startEditingRevisionEnd } from '../../../redux/actions/wasm_viewer';

function displayError(closeModal) {
  return (
    <div>
      <div className="modal-title">Editing in Progress</div>
      <div className="modal-body-paragraph">This smile design is currently being edited. Please try again later.</div>
      <div className="modal-button-group">
        <Button theme="success" onClick={closeModal}>
          Close
        </Button>
      </div>
    </div>
  );
}

function getModalDisplay(props, closeModal) {
  const { start_editing_revision_error } = props;
  if (!!start_editing_revision_error) {
    return displayError(closeModal);
  }
}

function StartEditingRevisionModal(props) {
  const { start_editing_revision_error, startEditingRevisionEnd } = props;

  const closeModal = () => {
    startEditingRevisionEnd();
  };

  return (
    <Modal show={start_editing_revision_error} show_close_button={start_editing_revision_error} onCloseModal={closeModal}>
      {start_editing_revision_error && <div className="wasm-save-draft-container">{getModalDisplay(props, closeModal)}</div>}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const wasmState = getWasmViewerState(state);
  return {
    start_editing_revision_error: wasmState.start_editing_revision_error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      startEditingRevisionEnd: startEditingRevisionEnd,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartEditingRevisionModal));
